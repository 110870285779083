import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import OrderSettingSummary from "./OrderSettingSummary";
import * as actions from "../orderSettingActions";
import { PICKUP } from "../orderSettingTypes";

let OrderSettingSummaryContainer = ({
  isForReviewPage,
  notEditable,
  currentLocation,
  hideToolTip,
  updateOrderSettingModalIsVisible,
  orderSettingReducer,
  locations,
  selectedDropOffLocation,
  setIsMenuDisabled,
  appConfig,
  isAuthenticated,
  selectedDeliveryAddress
}) => {
  const {
    interPickUpDetails,
    orderType,
    interOrderType,
    interDeliveryDetails,
    selectedDateTime,
    toolTipIsHidden,
    dateTimeToShow,
    specialHours,
    parentLocation,
    dropOffDetails,
    selectedLocation,
    isMenuDisabled,
  } = orderSettingReducer;

  const guest_checkout_enabled = appConfig ? appConfig.guest_checkout_enabled : false;

  const [nearestLocation, setNearestLocation] = useState(null);

  useEffect(() => {
    if (currentLocation && locations) {
      setNearestLocation(
        locations.find(
          (location) =>
            location.accepting_orders &&
            location.is_order_ahead_enabled &&
            location.accepted_order_types.includes(PICKUP)
        )
      );
    } else {
      setNearestLocation(null);
    }
  }, [locations, currentLocation]);

  if (
    interPickUpDetails ||
    nearestLocation ||
    interDeliveryDetails ||
    dropOffDetails ||
    dropOffDetails ||
    selectedLocation
  )
    return (
      <OrderSettingSummary
        notEditable={notEditable}
        nearestLocation={nearestLocation}
        hideToolTip={hideToolTip}
        toolTipIsHidden={toolTipIsHidden}
        isForReviewPage={isForReviewPage}
        updateOrderSettingModalIsVisible={updateOrderSettingModalIsVisible}
        interPickUpDetails={interPickUpDetails || nearestLocation}
        deliveryDetails={interDeliveryDetails}
        selectedDateTime={selectedDateTime}
        dateTimeToShow={dateTimeToShow}
        orderType={orderType}
        specialHours={specialHours}
        dropOffDetails={dropOffDetails}
        selectedDropOffLocation={selectedDropOffLocation}
        parentLocation={parentLocation}
        isMenuDisabled={isMenuDisabled}
        selectedLocation={selectedLocation}
        setIsMenuDisabled={setIsMenuDisabled}
        isAuthenticated={isAuthenticated}
        isGuestEnabled={guest_checkout_enabled}
        selectedDeliveryAddress={selectedDeliveryAddress}
      />
    );
  else return null;
};

const mapStateToProps = (state) => ({
  currentLocation: state.locationsReducer.currentLocation,
  orderSettingReducer: state.orderSettingReducer,
  locations: state.locationsReducer.locations,
  selectedDropOffLocation: state.dropOffLocationReducer.selectedDropOffLocation,
  appConfig: state.authReducer.appConfig,
  isAuthenticated: state.authReducer.isAuthenticated,
  selectedDeliveryAddress: state.deliveryAddressesReducer.deliveryToAddress,
});

export default connect(mapStateToProps, actions)(OrderSettingSummaryContainer);
