import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Footnote,
  Caption1,
  Subhead,
  Title3,
} from '../../../components/Typography';
import { PrimaryButton } from '../../../components/Buttons';
import { EditIcon } from '../../../components/Icons';
import { PICKUP, DELIVERY, ASAP, DROPOFF } from '../orderSettingTypes';
import Tooltip from '@material-ui/core/Tooltip';
import { isLocationAcceptingOrders } from '../OrderSettingModal/utils/helper';
import appConfig from '../../../../app-config';

const styles = {
  tooltip: {
    width: '220px',
    position: 'absolute',
    zIndex: 1,
    left: '-180px',
    bottom: '150%',
  },
  arrow: {
    '&::before': {
      position: 'absolute',
      right: '-180px',
    },
  },
};
const CustomTooltip = withStyles(styles)(Tooltip);
const DesktopWrapper = styled.div`
  display: grid;
  grid-gap: ${(props) => (props.isForReviewPage ? '0' : '12px')};
  grid-template-columns: ${(props) =>
    props.isForReviewPage ? '150px 1fr' : '1fr'};
  height: 100%;

  @media (max-width: 1100px) {
    display: ${(props) => (props.isForReviewPage ? 'grid' : 'none')};
  }
  @media (max-width: 768px) {
    grid-gap: 21px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const MobileWrapper = styled.div`
  display: ${(props) => (props.isForReviewPage ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  margin: 0 10%;
  @media (min-width: 1101px) {
    display: none;
  }

  @media (max-width: 540px) {
    height: auto;
  }
`;

const Closed = styled.span``;

const Edit = styled(Subhead)`
  color: ${(props) => props.theme.orderConfigEditColor};
  font-weight: 400;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  padding: ${(props) => (props.isForReviewPage ? '0 0 0 80px' : '0')};
  @media (max-width: 786px) {
    padding-top: 10px;
  }
`;

const Title3Wrapper = styled(Title3)`
  text-transform: ${(props) => props.theme.reviewCheckoutPageTitle};
  margin-bottom: auto;
  font-weight: 600;
  width: max-content;
  min-width: max-content;

  @media (max-width: 786px) {
    width: max-content;
  }

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const EditDiv = styled.div`
  color: #000;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  font-weight: 600;
  cursor: pointer;
  border-radius: 17.5px;
  border: solid 2px #000;
  padding: 0.25em 0 0.25em 1em;
  width: 80px;
  height: 30px;
`;

const FootnoteWrapper = styled(Footnote)`
  font-weight: 600;
  text-transform: ${(props) => props.theme.orderConfigFootNote};
  margin-bottom: 4px;
`;

const SpaceDiv = styled.div`
  margin: 20px;
`;

const Card = styled.div`
  display: grid;
  align-items: start;
  justify-content: start;
  background-color: ${(props) => props.theme.cartPreviewSidebarBackgroundColor};
  height: 100%;
  padding: 1.25em 1em;

  @media (max-width: 768px) {
    padding-top: 2em;
  }
`;

const DisabledCardTitle = styled('div')`
  font-size: 19px;
  font-weight: 600;
  text-align: left;
`;

const DisabledCardBody = styled('div')`
  font-size: 16px;
  text-align: left;
`;

const StartOrderWrapper = styled('div')`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 150px;
`;

const StartOrderButton = styled(PrimaryButton)`
  min-width: 150px;
  position: relative;
  width: 142px;
  min-width: 100px;
  border-radius: 17.5px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 1em;
`;

const OrderSettingSummary = ({
  notEditable,
  // nearestLocation,
  isForReviewPage,
  updateOrderSettingModalIsVisible,
  interPickUpDetails,
  deliveryDetails,
  selectedDateTime,
  orderType,
  dateTimeToShow,
  specialHours,
  parentLocation,
  dropOffDetails,
  isMenuDisabled,
  selectedLocation,
  setIsMenuDisabled,
  selectedDeliveryAddress,
}) => {
  let { line1, city, state } =
    orderType === PICKUP && interPickUpDetails
      ? interPickUpDetails
      : orderType === DELIVERY && selectedDeliveryAddress
      ? selectedDeliveryAddress
      : orderType === DROPOFF && dropOffDetails
      ? dropOffDetails
      : { line1: null, city: null, state: null };

  const { name, accepting_orders, is_order_ahead_enabled } =
    orderType === PICKUP && interPickUpDetails
      ? interPickUpDetails
      : orderType === DELIVERY &&
        deliveryDetails &&
        deliveryDetails.deliveryBusiness
      ? deliveryDetails.deliveryBusiness || {
          name: '',
          accepting_orders: true,
          is_order_ahead_enabled: true,
        }
      : orderType === DROPOFF && dropOffDetails
      ? dropOffDetails
      : { name: '', accepting_orders: false, is_order_ahead_enabled: false };

  let parentLine1 = parentLocation ? parentLocation.line1 : null;
  let parentCity = parentLocation ? parentLocation.city : null;
  let parentState = parentLocation ? parentLocation.state : null;

  let current_location =
    orderType === PICKUP && interPickUpDetails
      ? interPickUpDetails
      : orderType === DELIVERY && deliveryDetails
      ? deliveryDetails?.deliveryBusiness | deliveryDetails
      : selectedLocation;

  const renderPickUp = () => (
    <InfoWrapper isForReviewPage={isForReviewPage}>
      <FootnoteWrapper>Picking Up From:</FootnoteWrapper>
      <Caption1 style={{ textTransform: 'capitalize' }}>
        {(!accepting_orders ||
          !is_order_ahead_enabled ||
          !isLocationAcceptingOrders(accepting_orders, specialHours)) &&
          !selectedDateTime?.dateTime?.time?.name?.length && (
            <Closed>💤 Closed - </Closed>
          )}

        {appConfig.isParentChildClient
          ? interPickUpDetails?.name.toLowerCase()
          : name.toLowerCase()}
      </Caption1>
      <Caption1>
        {line1}, {city}, {state}
      </Caption1>
      {appConfig.isParentChildClient && orderType != PICKUP ? (
        <Fragment>
          <FootnoteWrapper style={{ marginTop: '5px' }}>
            Located in:
          </FootnoteWrapper>

          <div>
            {name}
            <div>
              {parentLine1}, {parentCity}, {parentState}
            </div>
          </div>
        </Fragment>
      ) : null}

      <SpaceDiv />

      <>
        <FootnoteWrapper>Pick Up Time:</FootnoteWrapper>
        <Caption1 style={{ textTransform: 'capitalize' }}>
          {dateTimeToShow.name}
        </Caption1>
      </>

      {!notEditable && isForReviewPage && (
        <div
          style={{
            display: 'flex',
            marginTop: '7px',
          }}
          onClick={() => updateOrderSettingModalIsVisible(true)}
        >
          <div style={{ marginRight: '5px' }}>
            <EditIcon />
          </div>
          <Edit>Edit</Edit>
        </div>
      )}
    </InfoWrapper>
  );
  const renderDelivery = () => (
    <InfoWrapper isForReviewPage={isForReviewPage}>
      <FootnoteWrapper>Delivery To:</FootnoteWrapper>
      <Caption1 style={{ textTransform: 'capitalize' }}>
        {/* {(!accepting_orders || !is_order_ahead_enabled) &&
          !selectedDateTime.dateTime.time.name.length && (
            <Closed>💤 Currently Unavailable - </Closed>
          )} */}
        {selectedDeliveryAddress?.type?.toLowerCase()}
      </Caption1>
      <Caption1>
        {`${line1 ? line1 + ', ' : ''} ${city ? city + ', ' : ''} ${state}`}
      </Caption1>
      <SpaceDiv />
      <FootnoteWrapper>Ordering From:</FootnoteWrapper>
      <Caption1 style={{ textTransform: 'capitalize' }}>
        {deliveryDetails && deliveryDetails.name ? deliveryDetails.name : null}
      </Caption1>
      <SpaceDiv />
      <>
        <FootnoteWrapper>Delivery Time:</FootnoteWrapper>
        <Caption1 style={{ textTransform: 'capitalize' }}>
          {dateTimeToShow.name}
        </Caption1>
      </>
      {!notEditable && isForReviewPage && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gridGap: '10px',
            marginTop: '7px',
          }}
        >
          <div>
            <EditIcon onClick={() => updateOrderSettingModalIsVisible(true)} />
          </div>
          <Edit onClick={() => updateOrderSettingModalIsVisible(true)}>
            Edit
          </Edit>
        </div>
      )}
    </InfoWrapper>
  );

  const renderDropOff = () => (
    <InfoWrapper isForReviewPage={isForReviewPage}>
      <FootnoteWrapper>Dine-in:</FootnoteWrapper>
      <Caption1 style={{ textTransform: 'capitalize' }}>
        {(!accepting_orders ||
          !is_order_ahead_enabled ||
          !isLocationAcceptingOrders(accepting_orders, specialHours)) &&
          !selectedDateTime.dateTime.time.name.length && (
            <Closed>💤 Closed - </Closed>
          )}
        {/* {name.toLowerCase()} */}
      </Caption1>
      <Caption1>
        {/* {line1}, {city}, {state} */}
        {dropOffDetails.finalDropOffLocation.map((selection, i) => {
          if (dropOffDetails.finalDropOffLocation.length === i + 1)
            return selection.value;
          return selection.value + ', ';
        })}
      </Caption1>
      <SpaceDiv />

      <>
        <FootnoteWrapper>Drop-off Time:</FootnoteWrapper>
        <Caption1 style={{ textTransform: 'capitalize' }}>
          {dateTimeToShow.name}
        </Caption1>
      </>

      {!notEditable && isForReviewPage && (
        <div
          style={{
            display: 'flex',
            marginTop: '7px',
          }}
          onClick={() => updateOrderSettingModalIsVisible(true)}
        >
          <div style={{ marginRight: '5px' }}>
            <EditIcon />
          </div>
          <Edit>Edit</Edit>
        </div>
      )}
    </InfoWrapper>
  );
  const renderPickUpOrDelivery = () => {
    return orderType === PICKUP
      ? renderPickUp()
      : orderType === DELIVERY
      ? renderDelivery()
      : orderType === DROPOFF
      ? renderDropOff()
      : null;
  };

  return (
    <Fragment>
      <DesktopWrapper isForReviewPage={isForReviewPage}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isForReviewPage ? (
            <Title3Wrapper>Order Method</Title3Wrapper>
          ) : (
            <Title3 style={{ fontWeight: '600' }}>Order Method</Title3>
          )}

          {!isForReviewPage && (
            <EditDiv onClick={() => updateOrderSettingModalIsVisible(true)}>
              <EditIcon /> Edit
            </EditDiv>
          )}
        </div>
        <div>{renderPickUpOrDelivery()}</div>
      </DesktopWrapper>
      {isMenuDisabled ? (
        <Card>
          <DisabledCardTitle>
            Order from{' '}
            {current_location
              ? current_location.name.split('-')[0]
              : 'current location'}
          </DisabledCardTitle>
          <DisabledCardBody>
            Browse our menu then click “Start Your Order” when you’re ready to
            go
          </DisabledCardBody>
          <StartOrderWrapper>
            <StartOrderButton
              onClick={() => {
                setIsMenuDisabled(false);
                updateOrderSettingModalIsVisible(true);
              }}
            >
              Start Your Order
            </StartOrderButton>
          </StartOrderWrapper>
        </Card>
      ) : (
        <MobileWrapper
          onClick={() => {
            // hideToolTip();
            updateOrderSettingModalIsVisible(true);
          }}
          isForReviewPage={isForReviewPage}
        >
          {renderPickUpOrDelivery()}
          <div>
            <i className="fal fa-chevron-down" />
          </div>
        </MobileWrapper>
      )}
    </Fragment>
  );
};

export default OrderSettingSummary;
